import { Button, Chip } from "@mui/material";
import { useFinishedAuditDetailsProvider } from "../../../context";

export function VarianceButton(): JSX.Element {
    const { auditVariances, changeSelectedView } = useFinishedAuditDetailsProvider();

    const onClickVariancesButton = (): void => {
        changeSelectedView("variance");
    }

    return (
        <Button onClick={onClickVariancesButton}>
            Variances&nbsp;<Chip label={auditVariances.length} />
        </Button>
    );
}