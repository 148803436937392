import { Box, Card, CardContent, Typography } from "@mui/material";
import { TemplateProps } from "./props";
import Barcode from "react-barcode";

export function BinLabelTemplate1({ bin }: TemplateProps): JSX.Element {
    return (
        <Card
            sx={{
                height: "1in",
                width: "3in"
            }}
            >
            <CardContent className="labelBin">
                <Box className="labelBinBarcode">
                    <Barcode
                        displayValue={false}
                        height={50}
                        renderer="svg"
                        value={bin}
                        width={1.0}
                        />
                </Box>
                <Typography
                    align="left"
                    className="labelBinDescription"
                    >
                    {bin}
                </Typography>
            </CardContent>
        </Card>
    );
}