import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { BinPrintTemplateCode } from "types/admin/printTemplate";

type BinLabelState = {
    selectedTemplate?: BinPrintTemplateCode
    selectedStyle: SxProps<Theme>
}

type BinLabelReducerAction =
    | { type: "SET_LOCATION_CODE", payload: BinPrintTemplateCode }
    | { type: "SET_SELECTED_STYLE", payload: SxProps<Theme> }

export const binLabelInitialState: BinLabelState = {
    selectedTemplate: undefined,
    selectedStyle: {},
};

export function BinLabelReducer(state: BinLabelState, action: BinLabelReducerAction): BinLabelState {
    switch (action.type) {
        case "SET_LOCATION_CODE":
            return {
                ...state,
                selectedTemplate: action.payload
            };
        case "SET_SELECTED_STYLE":
            return {
                ...state,
                selectedStyle: action.payload
            };
    }
}