import { SxProps, Theme } from "@mui/material";

export const binLabelStyle2: SxProps<Theme> = {
    ".gridCardCenterer": {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        marginBottom: 1,
        marginTop: 1,
        width: "100%",
    },
    ".labelBin": {
        backgroundColor: "#FFFFFF",
        border: "solid 1px black",
        borderRadius: 2,
        height: "1in",
        padding: 0,
        width: "3in",
    },
    ".labelBin .labelBinBarcode": {
        marginTop: 1.25,
        width: "1in"
    },
    ".labelBin .labelBinDescription": {
        fontSize: "2em",
        fontWeight: "bolder",
        marginTop: 1,
    },
    "@media print": {
        "body": {
            imageRendering: "-webkit-optimize-contrast",
            printColorAdjust: "exact",
            "-webkit-print-color-adjust": "exact",
        },
        "*": {
            fontSmooth: "never",
            textRendering: "optimizeLegibility",
            "-webkit-font-smoothing": "none",
        },
        "@page": {
            height: "1in",
            width: "3in",
        },
        "body, body *": {
            border: "none",
            borderRadius: 0,
            display: "none",
            margin: 0,
            padding: 0,
        },
        "&" /* Target the parent x-data-grid */: {
            border: "none",
            margin: 0,
            padding: 0,
        },
        ".MuiDataGrid-topContainer, .MuiDataGrid-topContainer *, .MuiDataGrid-footerContainer, .MuiDataGrid-footerContainer *": {
            border: "none",
            display: "none",
        },
        ".MuiDataGrid-scrollbar, .MuiDataGrid-scrollbar *, .MuiDataGrid-scrollbarFiller": {
            border: "none",
            borderRadius: 0,
            display: "none",
            margin: 0,
            padding: 0,
        },
        ".MuiDataGrid-row, .MuiDataGrid-cell": {
            border: "none",
            borderRadius: 0,
            display: "block",
            margin: 0,
            padding: 0,
        },
        ".gridCardCenterer": {
            display: "block",
            justifyContent: "left",
            margin: 0,
            padding: 0,
        },
        ".labelBin": {
            border: "none",
            borderRadius: 0,
            display: "block",
            height: "1in",
            margin: 0,
            width: "3in",
        },
        ".labelBin .labelBinBarcode": {
            display: "block",
        },
        " .labelBin .labelBinBarcode svg": {
        },
        ".labelBin .labelBinDescription": {
            display: "block",
        }
    }
}