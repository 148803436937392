import React from "react";
import { useApiService } from "../../../base/providers";
import { GetPrintInstructionsRespose, VehicleInformation } from "types/dsv/print";
import { dsvPrintSetupInitialState, DsvPrintSetupReducer } from "./reducer";

type DsvPrintSetupContextType = {
    stockNumberCsvStr: string
    vehicleInformationList: VehicleInformation[]
    submit: () => void
    updateStockNumberInput: (inputValue: string | undefined) => void
}

const DsvPrintSetupContext = React.createContext<DsvPrintSetupContextType | undefined>(undefined);

type DsvPrintSetupContextProviderProps = {
    children: React.ReactNode
}

export function DsvPrintSetupContextProvider(props: DsvPrintSetupContextProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DsvPrintSetupReducer, dsvPrintSetupInitialState);

    const getVehicleInformation = React.useCallback(() => {
        const stockNumbers = state.formState.stockNumbersCsvStr.split(",").map((item: string) => item.trim());
        apiService.dsv.generatePrintInstructions(stockNumbers)
            .then((response: GetPrintInstructionsRespose) => dispatch({ type: "SET_VEHICLE_INFORMATION", payload: response }))
            .catch((err) => console.error("An error occurred while printing", err));
    }, [apiService.dsv, state.formState]);

    const updateStockNumberInputCallback = React.useCallback((inputValue: string | undefined): void => {
        dispatch({ type: "SET_STOCK_NUMBER_CSV", payload: inputValue || "" });
    }, []);

    return (
        <DsvPrintSetupContext.Provider
            value={{
                stockNumberCsvStr: state.formState.stockNumbersCsvStr,
                vehicleInformationList: state.vehicleInformation,
                submit: getVehicleInformation,
                updateStockNumberInput: updateStockNumberInputCallback,
            }}
            >
            {props.children}
        </DsvPrintSetupContext.Provider>
    );
}

export function useDsvPrintSetupContext(): DsvPrintSetupContextType {
    const context = React.useContext(DsvPrintSetupContext);

    if (!context) {
        throw new Error("useDsvPrintSetupContext must be used within a DsvPrintSetupContextProvider");
    }

    return context;
}