import React from "react";
import { useBinLabelContext } from "../context";
import { BinLabelTemplate1, BinLabelTemplate2, BinLabelTemplate3 } from "../templates";

type BinLabelTemplateResolverProps = {
    bin: string
}

export function BinLabelTemplateResolver({ bin }: BinLabelTemplateResolverProps): JSX.Element {
    const { selectedTemplate } = useBinLabelContext();
    const [template, setTemplate] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setTemplate(() => {
            switch (selectedTemplate) {
                case "binLabelTemplate1":
                    return <BinLabelTemplate1 bin={bin} />
                case "binLabelTemplate2":
                    return <BinLabelTemplate2 bin={bin} />
                case "binLabelTemplate3":
                    return <BinLabelTemplate3 bin={bin} />
                case undefined:
                    return <></>;
            }
        });
    }, [selectedTemplate, bin]);

    return (template);
}