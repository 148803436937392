import React from "react";
import { Stack, Typography } from "@mui/material";
import { useDspAuditDetailsContext } from "../../../context";
import { AuditStatusResolver } from "../../../../base/dataGrid/statusResolver";

function TitleRow(): JSX.Element {
    const { auditName, auditStatus } = useDspAuditDetailsContext();
    const [auditStatusComponent, setAuditStatusComponent] = React.useState(<></>);

    React.useEffect(() => {
        setAuditStatusComponent(() => {
            if (!auditStatus) {
                return <></>;
            }

            return <AuditStatusResolver status={auditStatus} />;
        })
    }, [auditStatus]);


    return (
        <Stack
            direction="row"
            spacing={2}
            >
            <Typography variant="h6">{auditName}</Typography>
            {auditStatusComponent}
        </Stack>
    );
}

export { TitleRow }