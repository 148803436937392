import { GetBinPrintTemplateResponse, GetTypedPrintTemplateResponse } from "types/admin/printTemplate";
import { PrintUtilities } from "../../../../../../utilities/print";

type DspLabelSetupState = {
    binPrintTemplates: GetBinPrintTemplateResponse[]
    savedTemplate?: number
    selectedTemplate?: number
    hasRecord: boolean
}

type SetLocationOptionPayload = {
    savedTemplate?: number
    hasRecord: boolean
}

type DspLabelSetupReducerAction =
    | { type: "SET_TEMPLATES", payload: GetTypedPrintTemplateResponse[] }
    | { type: "SELECT_TEMPLATE", payload: number}
    | { type: "SET_LOCATION_OPTION", payload: SetLocationOptionPayload }

export const dspLabelSetupInitialState: DspLabelSetupState = {
    binPrintTemplates: [],
    savedTemplate: undefined,
    selectedTemplate: undefined,
    hasRecord: false
};

export function DspLabelSetupReducer(state: DspLabelSetupState, action: DspLabelSetupReducerAction): DspLabelSetupState {
    switch (action.type) {
        case "SET_TEMPLATES":
            const filteredPrintTemplates = action.payload.filter((value: GetTypedPrintTemplateResponse) => {
                return PrintUtilities.isBinPrintTemplateCode(value.code);
            }).map((value: GetTypedPrintTemplateResponse) => value as GetBinPrintTemplateResponse);

            return {
                ...state,
                binPrintTemplates: filteredPrintTemplates,
            };
        case "SELECT_TEMPLATE":
            return {
                ...state,
                selectedTemplate: action.payload

            };
        case "SET_LOCATION_OPTION":
            return {
                ...state,
                savedTemplate: action.payload.savedTemplate,
                hasRecord: action.payload.hasRecord,
            };
    }
}