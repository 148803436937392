import { Stack } from "@mui/material";
import { BackToAuditsButton } from "../../buttons";
import { AuditDetailsTitleRowView } from "./titleRow";
import { PendingDetailsDataGrid } from "../../dataGrid";
import { InProgressAuditDetailsProvider } from "./context";

function PendingDetailsView(): JSX.Element {
    return (
        <InProgressAuditDetailsProvider>
            <Stack
                direction="column"
                spacing={2}
                >
                <BackToAuditsButton />
                <AuditDetailsTitleRowView />
                <PendingDetailsDataGrid />
            </Stack>
        </InProgressAuditDetailsProvider>
    );
}

export { PendingDetailsView }