import { useFinishedAuditDetailsProvider } from "../../../context";
import { BaseCard } from "../base";

function BinsCard(): JSX.Element {
    const { binReports } = useFinishedAuditDetailsProvider();
    return (
        <BaseCard
            title="Bins"
            value={binReports.length}
            />
    );
}

export { BinsCard }