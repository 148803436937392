import React from "react";
import { Stack } from "@mui/material";
import { AcceptPendingVarianceButton } from "../acceptPendingVarianceButton";
import { RequestRescanButton } from "../requestRescanButton";
import { useDspAuditVarianceContext } from "../../context";
import {CancelRescanButton} from "../cancelRescanButton";
import { RequestRefreshButton } from "../requestRefresh";

function OptionsResolver(): JSX.Element {
    const { selectedTab } = useDspAuditVarianceContext();
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (selectedTab) {
                case "pending":
                    return (
                        <Stack
                            direction="row"
                            spacing={2}
                            >
                            <RequestRescanButton key="request-rescan" />
                            <AcceptPendingVarianceButton key="accept-pending-variance" />
                            <RequestRefreshButton key="request-refresh" />
                        </Stack>
                    );
                case "rescans":
                    return (
                        <Stack
                            direction="row"
                            spacing={2}
                            >
                            <CancelRescanButton key="cancel-rescan" />
                            <RequestRefreshButton key="request-refresh" />
                        </Stack>
                    );
                case "mismatch":
                    return (
                        <RequestRefreshButton key="request-refresh" />
                    );
                default: 
                    return (<></>);
            }
        });
    }, [selectedTab]);

    return (component);
}

export { OptionsResolver }