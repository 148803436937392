import React from "react";
import { useParams } from "react-router-dom";
import { useApiService } from "../../../../base/providers";
import { GetDspAuditsResponse } from "types/dsp/audits";
import { auditDetailsInitialState, AuditDetailsReducer } from "./reducer";

type DspAuditDetailsContextType = {
    auditName?: string
    auditStatus?: number
}

const DspAuditDetailsContext = React.createContext<DspAuditDetailsContextType | undefined>(undefined);

type DspAuditDetailsProviderProps = {
    children: React.ReactNode
}

function DspAuditDetailsProvider(props: DspAuditDetailsProviderProps): JSX.Element {
    const { auditId } = useParams();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(AuditDetailsReducer, auditDetailsInitialState);

    const getBasicAuditInfoCallback = React.useCallback(() => {
        apiService.dsp.getAudit(Number(auditId))
            .then((value: GetDspAuditsResponse) => {
                const audit = value.audits[0];

                dispatch({
                    type: "SET_AUDIT_INFO",
                    payload: {
                        auditName: audit.auditName,
                        auditStatus: audit.status,
                    }
                });
                return audit.status;
            })
            .catch((err: any) => console.error("Unable to get basic audit info."));

    }, [apiService.dsp, auditId]);

    React.useEffect(() => {
        getBasicAuditInfoCallback();
    }, [getBasicAuditInfoCallback]);

    return (
        <DspAuditDetailsContext.Provider
            value={{
                auditName: state.auditName,
                auditStatus: state.auditStatus,
            }}
            >
            {props.children}
        </DspAuditDetailsContext.Provider>
    );
}

function useDspAuditDetailsContext(): DspAuditDetailsContextType {
    const context = React.useContext(DspAuditDetailsContext);

    if (!context) {
        throw new Error("useDspAuditDetailsContext must be used within a DspAuditDetailsProvider");
    }

    return context;
}

export { DspAuditDetailsProvider, useDspAuditDetailsContext }