import React from "react";
import { useParams } from "react-router-dom";
import { 
    DspAuditBinReport,
    DspAuditVarianceReport,
    GetAuditReportResponse,
} from "types/dsp/audits";
import { finishedAuditInitialState, FinishedAuditReducer } from "./reducer";
import { useApiService } from "../../../../../../base/providers";

type FinishedAuditDetailsContextType = {
    auditVariances: DspAuditVarianceReport[]
    binReports: DspAuditBinReport[]
    selectedView: "variance" | "bins"
    changeSelectedView: (selectedView: "variance" | "bins") => void
}

const FinishedAuditDetailsContext = React.createContext<FinishedAuditDetailsContextType | undefined>(undefined);

type FinishedAuditDetailsProviderProps = {
    children: React.ReactNode
}

function FinishedAuditDetailsProvider(props: FinishedAuditDetailsProviderProps): JSX.Element {
    const { auditId } = useParams();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(FinishedAuditReducer, finishedAuditInitialState);

    
    const changeSelectedViewCallback = React.useCallback((selectedView: "variance" | "bins"): void => {
        dispatch({ type: "SET_SELECTED_VIEW", payload: selectedView });
    }, []);

    React.useEffect(() => {
        apiService.dsp.getCompletedAuditDetails(Number(auditId))
            .then((value: GetAuditReportResponse) => dispatch({ type: "SET_AUDIT_DETAILS", payload: value }))
            .catch((err: any) => console.error("Unable to get completed audit details."));
    }, [apiService.dsp, auditId]);

    return (
        <FinishedAuditDetailsContext.Provider
            value={{
                auditVariances: state.auditVariances,
                binReports: state.binReports,
                selectedView: state.selectedView,
                changeSelectedView: changeSelectedViewCallback,
            }}
            >
            {props.children}
        </FinishedAuditDetailsContext.Provider>
    );
}

function useFinishedAuditDetailsProvider(): FinishedAuditDetailsContextType {
    const context = React.useContext(FinishedAuditDetailsContext);

    if (!context) {
        throw new Error("useFinishedAuditDetailsProvider must be used within a FinishedAuditDetailsProvider");
    }

    return context;
}

export { FinishedAuditDetailsProvider, useFinishedAuditDetailsProvider }