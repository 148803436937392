import { Stack } from "@mui/material";
import { BackToAuditsButton } from "../../buttons";
import { CompletedDataGridResolver } from "../../dataGrid/complete";
import { CardRow } from "./cardRow";
import { TitleRow } from "./titleRow";
import { CompleteViewOptionsRow } from "./optionRow";
import { FinishedAuditDetailsProvider } from "./context";

function CompleteDetailsView(): JSX.Element {
    return (
        <FinishedAuditDetailsProvider>
            <Stack
                direction="column"
                spacing={2}
                >
                <BackToAuditsButton />
                <TitleRow />
                <CardRow />
                <CompleteViewOptionsRow />
                <CompletedDataGridResolver />
            </Stack>
        </FinishedAuditDetailsProvider>
    );
}

export { CompleteDetailsView }