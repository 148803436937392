import React from "react";
import { useParams } from "react-router-dom";
import { 
    DspAuditBin,
    DspAuditPart,
    FinishAuditResponse,
    GetDspAuditDetailsResponse,
} from "types/dsp/audits";
import { inProgressAuditInitialState, InProgressAuditReducer } from "./reducer";
import { useApiService } from "../../../../../../base/providers";
import { useDspAuditDetailsContext } from "../../../context";

type InProgressAuditDetailsContextType = {
    auditBins: DspAuditBin[]
    auditParts: DspAuditPart[]
    canFinishAudit: boolean
    selectedBins: Set<string>
    changeIsRowSelected: (binName: string) => void
    finishAudit: () => Promise<FinishAuditResponse>
    requestBinVarianceRecalculation: (auditBinId: number) => Promise<void>
}

const InProgressAuditDetailsContext = React.createContext<InProgressAuditDetailsContextType | undefined>(undefined);

type InProgressAuditDetailsProviderProps = {
    children: React.ReactNode
}

function InProgressAuditDetailsProvider(props: InProgressAuditDetailsProviderProps): JSX.Element {
    const { auditId } = useParams();
    const { auditStatus } = useDspAuditDetailsContext();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(InProgressAuditReducer, inProgressAuditInitialState);

    const canFinishAuditMemo = React.useMemo((): boolean => {
        return state.auditBins.every((value: DspAuditBin) => value.binStatus === 2);
    }, [state.auditBins]);

    const getAuditDetailsCallback = React.useCallback(() => {
        if (!auditId) {
            return;
        }

        apiService.dsp.getAuditDetails(auditId)
            .then((value: GetDspAuditDetailsResponse) => dispatch({ type: "SET_AUDIT_DETAILS", payload: value }))
            .catch((err: any) => console.error("Unable to get completed audit details."));
    }, [apiService.dsp, auditId]);
    
    const finishAuditCallback = React.useCallback((): Promise<FinishAuditResponse> => {
        return new Promise((resolve, reject) => {
            apiService.dsp.finishAudit(Number(auditId))
                .then((response: FinishAuditResponse) => resolve(response))
                .catch((err) => reject(err));
        });
    }, [apiService, auditId]);
    
    const changeSelectedBinsCallback = React.useCallback((binName: string): void => {
        dispatch({ type: "CHANGE_SINGLE_BIN_SELECTION", payload: binName });
    }, []);
    
    const requestBinVarianceRecalculationCallback = React.useCallback((auditBinId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            apiService.dsp.recalculateVariance(Number(auditId), auditBinId)
                .then(() => getAuditDetailsCallback())
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, auditId, getAuditDetailsCallback]);

    React.useEffect(() => {
        if (auditStatus === undefined) {
            return;
        }

        getAuditDetailsCallback();
    }, [auditStatus, getAuditDetailsCallback]);

    return (
        <InProgressAuditDetailsContext.Provider
            value={{
                auditBins: state.auditBins,
                auditParts: state.auditParts,
                canFinishAudit: canFinishAuditMemo,
                selectedBins: state.selectedBins,
                changeIsRowSelected: changeSelectedBinsCallback,
                finishAudit: finishAuditCallback,
                requestBinVarianceRecalculation: requestBinVarianceRecalculationCallback,
            }}
            >
            {props.children}
        </InProgressAuditDetailsContext.Provider>
    );
}

function useInProgressAuditDetailsProvider(): InProgressAuditDetailsContextType {
    const context = React.useContext(InProgressAuditDetailsContext);

    if (!context) {
        throw new Error("useInProgressAuditDetailsProvider must be used within a InProgressAuditDetailsProvider");
    }

    return context;
}

export { InProgressAuditDetailsProvider, useInProgressAuditDetailsProvider }