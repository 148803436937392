import React from "react"
import { DspAuditBin } from "../../../../../../types/dsp/audits"
import { GenericDataGrid } from "../../../../../base/dataGrid"
import { columns } from "./columns"
import { Utilities } from "../../../../../../utilities"
import { useInProgressAuditDetailsProvider } from "../../view/pending/context"

type MappedRow = {
    id: number
    binName: string
    binStatus: number
    uniqueParts: number
    onHand: number
    totalValue: number
    timeToScan: string
    submitted: Date
    isSelected: boolean
}

export function PendingDetailsDataGrid(): JSX.Element {
    const { auditBins, selectedBins, changeIsRowSelected } = useInProgressAuditDetailsProvider();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);
    
    React.useEffect(() => {
        if (!auditBins) {
            return;
        }
        
        setMappedRows(auditBins.map((auditBin: DspAuditBin, index: number) => {
            return {
                id: auditBin.id,
                binName: auditBin.binName,
                binStatus: auditBin.binStatus,
                uniqueParts: auditBin.uniqueParts,
                onHand: auditBin.totalParts,
                totalValue: auditBin.binDollarValue,
                timeToScan: Utilities.displayDistanceBetweenDates(auditBin.submittedDate, auditBin.createdDate),
                submitted: auditBin.submittedDate,
                assigned: "Not Yet Implemented",
                isSelected: false
            }
        }));
    }, [auditBins]);

    return (
        <GenericDataGrid
            columns={columns({
                selectedRows: selectedBins,
                onChangeIsRowSelectedCallback: changeIsRowSelected
            })}
            columnVisibilityModel={{
                id: false,
                type: false,
                assigned: false,
                isSelected: false
            }}
            initialState={{
                pagination: {
                    paginationModel: {
                        page: 0,
                        pageSize: 25,
                    }
                },
                sorting: {
                    sortModel: [{ field: "binName", sort: "asc" }]
                }
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            rows={mappedRows}
            />
    );
}