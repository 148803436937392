import React from "react";
import { GenericDataGrid } from "../../../../../../base/dataGrid";
import { columns } from "./columns";
import { VarianceMappedRow } from "./types";
import { DspAuditVarianceReport } from "types/dsp/audits";
import { useFinishedAuditDetailsProvider } from "../../../view/complete/context";

export function CompleteVarianceDetailsDataGrid(): JSX.Element {
    const { auditVariances } = useFinishedAuditDetailsProvider();
    const [mappedRows, setMappedRows] = React.useState<VarianceMappedRow[]>([]);

    React.useEffect(() => {
        setMappedRows(auditVariances.filter((value: DspAuditVarianceReport) => value.expected !== value.scanned)
            .map((value: DspAuditVarianceReport, index: number) => {
                return {
                    id: index,
                    partNumber: value.partNumber,
                    bins: value.bins,
                    partCost: value.partCost,
                    expected: value.expected,
                    scanned: value.scanned,
                    quantityVariance: value.newQuantity
                        ? value.newQuantity - value.expected
                        : value.scanned - value.expected,
                    dollarVariance: value.newQuantity
                        ? (value.newQuantity - value.expected) * value.partCost
                        : (value.scanned - value.expected) * value.partCost,
                    newQuantity: value.newQuantity ? value.newQuantity : null,
                    override: value.newQuantity !== null && value.scanned !== value.newQuantity,
                    reconciled: value.newQuantity !== null
                };
            }));
    }, [auditVariances]);

    return (
        <GenericDataGrid
            columns={columns}
            columnVisibilityModel={{
                id: false
            }}
            getRowHeight={() => "auto"}
            rows={mappedRows}
            sx={{
                "& .MuiDataGrid-cell": {
                    alignItems: "center",
                    display: "flex",
                },
                "& .cellWordWrap": {
                    overflow: "visible",
                    textOverflow: "clip",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
            }}
            />
    );
}