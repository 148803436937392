import React, { useImperativeHandle } from "react";
import { PartDetails } from "../../../types/dsp/print";
import { PrintableTemplateHandle } from "../../../types/print/PrintableTemplateHandle";
import { StockLabelTemplate } from "./stockLabel/stockLabelTemplate";
import { VehicleInformation } from "../../../types/dsv/print";
import { Box, Stack, Typography } from "@mui/material";
import { printTemplateWebStyle } from "./styles";

type PrintTemplateResolverProps = {
    printType: "bin" | "stock" | ""
    partDetails: PartDetails[]
    foundStock: VehicleInformation[]
}

const PrintTemplateResolver = React.forwardRef<PrintableTemplateHandle, PrintTemplateResolverProps>((props, ref) => {
    const [printTemplate, setPrintTemplate] = React.useState<React.ReactNode>();
    const binLabelTemplateRef = React.useRef<PrintableTemplateHandle>(null);
    const stockLabelTemplateRef = React.useRef<PrintableTemplateHandle>(null);

    useImperativeHandle(ref, () => ({
        print: () => {
            switch (props.printType) {
                case "bin":
                    binLabelTemplateRef.current?.print();
                    break;
                case "stock":
                    stockLabelTemplateRef.current?.print();
                    break;
                default:
                    break;
            }
        }
    }));

    React.useEffect(() => {
        setPrintTemplate(() => {
            switch(props.printType) {
                case "stock":
                    return (
                        <StockLabelTemplate
                            ref={stockLabelTemplateRef}
                            partDetails={props.partDetails}
                            />
                    );
                default:
                    return (<></>);
            }
        });
    }, [props.printType, props.partDetails, props.foundStock]);

    return (
        <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            >
            <Typography variant="h5">Print Preview</Typography>
            <Box sx={printTemplateWebStyle}>
                {printTemplate}
            </Box>
        </Stack>
    )
});

export { PrintTemplateResolver }