import {
    DspAuditBin,
    DspAuditPart,
    GetDspAuditDetailsResponse,
} from "types/dsp/audits";

type InProgressAuditStateType = {
    auditBins: DspAuditBin[]
    auditParts: DspAuditPart[]
    selectedBins: Set<string>
}

type InProgressAuditReducerActions = 
    | { type: "SET_AUDIT_DETAILS", payload: GetDspAuditDetailsResponse }
    | { type: "CHANGE_SINGLE_BIN_SELECTION", payload: string }

const inProgressAuditInitialState: InProgressAuditStateType = {
    auditBins: [],
    auditParts: [],
    selectedBins: new Set<string>(),
};

function InProgressAuditReducer(state: InProgressAuditStateType, action: InProgressAuditReducerActions): InProgressAuditStateType {
    switch (action.type) {
        case "SET_AUDIT_DETAILS":
            return {
                ...state,
                auditBins: action.payload.auditBins,
                auditParts: action.payload.auditParts,
            };
        case "CHANGE_SINGLE_BIN_SELECTION":
            const newSelectedBins = new Set(state.selectedBins);
            const binName = action.payload;

            if (newSelectedBins.has(binName)) {
                newSelectedBins.delete(binName);
            } else {
                newSelectedBins.add(binName);
            }

            return {
                ...state,
                selectedBins: newSelectedBins
            };
    }
}

export {
    inProgressAuditInitialState,
    InProgressAuditReducer,
}