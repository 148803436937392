import { Card, CardContent, Stack, Typography, Box } from "@mui/material";
import Barcode from "react-barcode";
import { TemplateProps } from "./props";

export function BinLabelTemplate3({ bin }: TemplateProps): JSX.Element {
    return (
        <Card
            sx={{
                height: "1in",
                width: "3in"
            }}
            >
            <CardContent className="labelBin">
                <Stack
                    alignItems="center"
                    direction="column"
                    spacing={1}
                    justifyContent="center"
                    >
                    <Typography
                        align="left"
                        className="labelBinDescription"
                        >
                        {bin}
                    </Typography>
                    <Box className="labelBinBarcode">
                        <Barcode
                            displayValue={false}
                            height={20}
                            renderer="svg"
                            value={bin}
                            width={1.25}
                            />
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}