import React from "react";
import { BaseCard } from "../base";
import { DspAuditBinReport } from "types/dsp/audits";
import { useFinishedAuditDetailsProvider } from "../../../context";

function NetDollarVarianceCard(): JSX.Element {
    const { binReports } = useFinishedAuditDetailsProvider();
    const [displayValue, setDisplayValue] = React.useState(0);

    React.useEffect(() => {
        const netDollarVariance = binReports.map((value: DspAuditBinReport) => {
                return value.dollarVariance;
            })
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
            
        setDisplayValue(netDollarVariance);
    }, [binReports]);
    
    return (
        <BaseCard
            displayValueAs="currency"
            title="Net Dollar Variance"
            value={displayValue}
            useAccountingColors={true}
            />
    );
}

export { NetDollarVarianceCard }