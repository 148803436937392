import React from "react";
import { BaseCard } from "../base";
import { DspAuditBinReport } from "types/dsp/audits";
import { useFinishedAuditDetailsProvider } from "../../../context";

function TotalPartsCard(): JSX.Element {
    const { binReports } = useFinishedAuditDetailsProvider();
    const [displayValue, setDisplayValue] = React.useState(0);

    React.useEffect(() => {
        const totalParts = binReports.map((value: DspAuditBinReport) => value.quantityOnHand)
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
            
        setDisplayValue(totalParts);
    }, [binReports]);
    
    return (
        <BaseCard
            title="Total Parts"
            value={displayValue}
            />
    );
}

export { TotalPartsCard }