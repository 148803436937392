import React from "react";
import { GetPrintServiceLocationResponse } from "types/print/printServiceLocation";
import { useApiService } from "../../../providers";
import { binLabelInitialState, BinLabelReducer } from "./reducer";
import { BinPrintTemplateCode } from "types/admin/printTemplate";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { binLabelStyle1, binLabelStyle2, binLabelStyle3 } from "../style";

type BinLabelContextType = {
    selectedTemplate?: BinPrintTemplateCode
    selectedStyle: SxProps<Theme>
}

const BinLabelContext = React.createContext<BinLabelContextType | undefined>(undefined);

type BinLabelContextProviderProps = {
    children: React.ReactNode
}

export function BinLabelContextProvider(props: BinLabelContextProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(BinLabelReducer, binLabelInitialState);

    const getSavedPrintTemplateCallback = React.useCallback((): void => {
        apiService.printServiceLocation.getOne()
            .then((response: GetPrintServiceLocationResponse) => dispatch({ type: "SET_LOCATION_CODE", payload: response.binPrintTemplate.code }))
            .catch((err) => console.error(err));
    }, [apiService.printServiceLocation]);

    React.useEffect(() => {
        getSavedPrintTemplateCallback();
    }, [getSavedPrintTemplateCallback]);

    React.useEffect(() => {
        switch (state.selectedTemplate) {
            case "binLabelTemplate1":
                dispatch({ type: "SET_SELECTED_STYLE", payload: binLabelStyle1 });
                break;
            case "binLabelTemplate2":
                dispatch({ type: "SET_SELECTED_STYLE", payload: binLabelStyle2 });
                break;
            case "binLabelTemplate3":
                dispatch({ type: "SET_SELECTED_STYLE", payload: binLabelStyle3 });
                break;
            case undefined:
                dispatch({ type: "SET_SELECTED_STYLE", payload: {} });
                break;
        }
    }, [state.selectedTemplate]);

    return (
        <BinLabelContext.Provider
            value={{
                selectedTemplate: state.selectedTemplate,
                selectedStyle: state.selectedStyle,
            }}
            >
            {props.children}
        </BinLabelContext.Provider>
    );
}

export function useBinLabelContext(): BinLabelContextType {
    const context = React.useContext(BinLabelContext);

    if (!context) {
        throw new Error("useBinLabelContext must be used within a BinLabelContextProvider");
    }

    return context;
}