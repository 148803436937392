import React from "react";
import { useDspAuditDetailsContext } from "../../context";
import { CompleteDetailsView, PendingDetailsView } from "../../view";

function ViewResolver(): JSX.Element {
    const { auditStatus } = useDspAuditDetailsContext();
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (auditStatus) {
                case 0: // Not Started
                case 1: // In Progress
                    return (<PendingDetailsView />);
                case 2: // Completed
                case 3: // Cancelled
                    return (<CompleteDetailsView />);
                case undefined: // For when page is not loaded yet.
                    return <></>;
                default:
                    throw new Error("Unable to resolve audit state");
            }
        });
        
    }, [auditStatus]);

    return (component);
}

export { ViewResolver }