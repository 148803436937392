import React from "react";
import { FinishAuditEnabledButton } from "./enabled";
import { FinishAuditDisabledButton } from "./disabled";
import { useInProgressAuditDetailsProvider } from "../../view/pending/context";

function FinishAuditButton(): JSX.Element {
    const { canFinishAudit } = useInProgressAuditDetailsProvider();
    const [component, setComponent] = React.useState<JSX.Element>(<FinishAuditDisabledButton />);

    React.useEffect(() => {
        switch (canFinishAudit) {
            case true:
                setComponent(<FinishAuditEnabledButton />);
                break;
            case false:
                setComponent(<FinishAuditDisabledButton />);
                break;
        }
    }, [canFinishAudit]);

    return (component);
}

export { FinishAuditButton }