import React from "react";
import { useApiService } from "../../../base/providers";
import { dspPrintSetupInitialState, DspPrintSetupReducer } from "./reducer";
import { GetDspPrintInstructionsRespose } from "types/dsp/print";

type DspPrintSetupContextType = {
    binsCsv: string
    foundBinsList: string[]
    setBinsCsv: (input: string) => void
    submitBinLabels: () => Promise<void>
}

const DspPrintSetupContext = React.createContext<DspPrintSetupContextType | undefined>(undefined);

type DspPrintSetupContextProviderProps = {
    children: React.ReactNode
}

export function DspPrintSetupContextProvider(props: DspPrintSetupContextProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DspPrintSetupReducer, dspPrintSetupInitialState);

    const setBinsCsvCallback = React.useCallback((input: string): void => {
        dispatch({ type: "SET_BINS_CSV", payload: input });
    }, []);

    const submitBinLabelsCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                const binRequests = state.binsCsv.split(",").map((item: string) => item.trim());

                const response: GetDspPrintInstructionsRespose = await apiService.dsp.generatePrintInstructions(binRequests, 24);

                dispatch({ type: "SET_BIN_INFORMATION", payload: response.foundStock });

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService.dsp, state.binsCsv]);

    return (
        <DspPrintSetupContext.Provider
            value={{
                binsCsv: state.binsCsv,
                foundBinsList: state.foundBins,
                setBinsCsv: setBinsCsvCallback,
                submitBinLabels: submitBinLabelsCallback,
            }}
            >
            {props.children}
        </DspPrintSetupContext.Provider>
    );
}

export function useDspPrintSetupContext(): DspPrintSetupContextType {
    const context = React.useContext(DspPrintSetupContext);

    if (!context) {
        throw new Error("useDspPrintSetupContext must be used within a DspPrintSetupContextProvider");
    }

    return context;
}