import { Chip } from "@mui/material";
import { useInProgressAuditDetailsProvider } from "../../view/pending/context";

type BinStatusResolverProps = {
    status: number
    auditBinId: number
}

function BinStatusResolver(props: BinStatusResolverProps): JSX.Element {
    const { requestBinVarianceRecalculation } = useInProgressAuditDetailsProvider();

    const handleClick = () => {
        requestBinVarianceRecalculation(props.auditBinId)
            .catch((err) => console.error("Unable to recalculate variance", err));
    };

    switch (props.status) {
        case 0: // Not Started
            return (
                <Chip
                    color="primary"
                    label="Not Started"
                    />
            );
        case 1: // Pending Variance
            return (
                <Chip
                    color="warning"
                    label="Pending Variance"
                    onClick={handleClick}
                    />
            );
        case 2: // Finished
            return (
                <Chip
                    color="success"
                    label="Finished"
                    />
            );
        case 4: // Pending Rescan
            return (
                <Chip
                    color="warning"
                    label="Pending Rescan"
                    />
            );
        case 5: // In Progress
            return (
                <Chip
                    color="warning"
                    label="In Progress"
                    onClick={handleClick}
                    />
            );
        case 3: // Error
        default:
            return (
                <Chip
                    color="error"
                    label="Error"
                    />
            );
    }
}

export { BinStatusResolver }