import { Button } from "@mui/material";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useDspAuditVarianceContext } from "../../context";

export function RequestRefreshButton(): JSX.Element {
    const { requestCacheRefresh } = useDspAuditVarianceContext();

    const onClickRequestRefresh = (): void => {
        requestCacheRefresh();
    }

    return (
        <Button
            onClick={onClickRequestRefresh}
            variant="outlined"
            >
            <RefreshOutlinedIcon />
        </Button>
    )
}