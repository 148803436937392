import { PartDetails } from "types/dsp/print";

type DspPrintSetupState = {
    binsCsv: string
    foundBins: string[]
}

type DspPrintSetupReducerAction =
    | { type: "SET_BINS_CSV", payload: string}
    | { type: "SET_BIN_INFORMATION", payload: PartDetails[]}

export const dspPrintSetupInitialState: DspPrintSetupState = {
    binsCsv: "",
    foundBins: [],
};

export function DspPrintSetupReducer(state: DspPrintSetupState, action: DspPrintSetupReducerAction): DspPrintSetupState {
    switch (action.type) {
        case "SET_BINS_CSV":
            return {
                ...state,
                binsCsv: action.payload,
            };
        case "SET_BIN_INFORMATION":
            return {
                ...state,
                foundBins: action.payload.map((value: PartDetails) => value.binOne),
            };
    }
}