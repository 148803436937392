import React from "react";
import { GetTypedPrintTemplateResponse } from "types/admin/printTemplate";
import { dspLabelSetupInitialState, DspLabelSetupReducer } from "./reducer";
import { GetPrintServiceLocationResponse } from "types/print/printServiceLocation";
import { useApiService } from "../../../../base/providers";

type DspLabelSetupContextType = {
    printTemplates: GetTypedPrintTemplateResponse[]
    savedTemplate?: number
    selectedTemplate?: number
    reloadData: () => void
    selectTemplate: (id: number) => void
    submit: () => Promise<void>
}

const DspLabelSetupContext = React.createContext<DspLabelSetupContextType | undefined>(undefined);

type DspLabelSetupContextProviderProps = {
    children: React.ReactNode
}

export function DspLabelSetupContextProvider(props: DspLabelSetupContextProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DspLabelSetupReducer, dspLabelSetupInitialState);

    const getSavedPrintTemplateCallback = React.useCallback((): void => {
        apiService.printServiceLocation.getOne()
            .then((response: GetPrintServiceLocationResponse) => {
                dispatch({
                    type: "SET_LOCATION_OPTION",
                    payload: {
                        savedTemplate: response.binPrintTemplate?.id,
                        hasRecord: true,
                    }
                });
            }).catch((err) => {
                dispatch({
                    type: "SET_LOCATION_OPTION",
                    payload: {
                        savedTemplate: undefined,
                        hasRecord: false,
                    }
                });
                console.error(err)
            });
    }, [apiService.printServiceLocation])

    const getAllPrintTemplatesCallback = React.useCallback((): void => {
        apiService.adminPrintTemplate.getAll()
            .then((allPrintTemplates: GetTypedPrintTemplateResponse[]) => dispatch({ type: "SET_TEMPLATES", payload: allPrintTemplates }))
            .catch(err => console.error("Unable to get all print templates", err));
    }, [apiService.adminPrintTemplate]);

    const reloadDataCallback = React.useCallback((): void => {
        getSavedPrintTemplateCallback();
        getAllPrintTemplatesCallback();
    }, [getSavedPrintTemplateCallback, getAllPrintTemplatesCallback])

    const selectTemplateCallback = React.useCallback((id: number): void => {
        dispatch({ type: "SELECT_TEMPLATE", payload: id });
    }, []);

    const saveChangesCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!state.selectedTemplate) {
                    throw new Error("Template not selected.");
                }
                
                if (state.savedTemplate === undefined && state.hasRecord === false) {
                    await apiService.printServiceLocation.create({ binPrintTemplateId: state.selectedTemplate });
                } else {
                    await apiService.printServiceLocation.updateBinTemplate(state.selectedTemplate);
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService, state.selectedTemplate, state.savedTemplate, state.hasRecord])

    React.useEffect(() => {
        getAllPrintTemplatesCallback();
    }, [getAllPrintTemplatesCallback]);

    React.useEffect(() => {
        getSavedPrintTemplateCallback();
    }, [getSavedPrintTemplateCallback]);

    return (
        <DspLabelSetupContext.Provider
            value={{
                printTemplates: state.binPrintTemplates,
                savedTemplate: state.savedTemplate,
                selectedTemplate: state.selectedTemplate,
                reloadData: reloadDataCallback,
                selectTemplate: selectTemplateCallback,
                submit: saveChangesCallback,
            }}
            >
            {props.children}
        </DspLabelSetupContext.Provider>
    );
}

export function useDspLabelSetupContext(): DspLabelSetupContextType {
    const context = React.useContext(DspLabelSetupContext);

    if (!context) {
        throw new Error("useDspLabelSetupContext must be used within a DspLabelSetupContextProvider");
    }

    return context;
}