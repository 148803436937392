import { Stack, Typography } from "@mui/material";
import { DspLabelSetupContextProvider } from "./context";
import { BinLabelsSetupForm } from "./binForm";

export function PartsLabelLayoutsView(): JSX.Element {
    return (
        <DspLabelSetupContextProvider>
            <Stack
                direction="column"
                spacing={2}
                >
                <Typography variant="h5">Bin Label Default</Typography>
                <Typography variant="body2">Choose which label you would like as your default (only choose one at a time)</Typography>
                <BinLabelsSetupForm />
            </Stack>
        </DspLabelSetupContextProvider>
    );
}