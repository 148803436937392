import { AxiosInstance } from "axios";
import { ApiService } from "./apiService";
import { CreatePrintTemplateRequest, CreatePrintTemplateResponse, GetTypedPrintTemplateResponse, UpdatePrintTemplateRequest } from "types/admin/printTemplate";

export class AdminPrintTemplateApiService extends ApiService {
    super(api: AxiosInstance) { }

    getAll(): Promise<GetTypedPrintTemplateResponse[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/api/printtemplate/all");
    
                const getAllPrintTemplateResponse = response.data as GetTypedPrintTemplateResponse[];
                resolve(getAllPrintTemplateResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    getOne(id: number): Promise<GetTypedPrintTemplateResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/api/printtemplate");
    
                const getPrintTemplateResponse = response.data as GetTypedPrintTemplateResponse;
                resolve(getPrintTemplateResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    create(height: number, width: number, description: string, templateImageSvgPath: string): Promise<number> {
        return new Promise(async (resolve, reject) => {
            try {
                const requestBody: CreatePrintTemplateRequest = {
                    height,
                    width,
                    description,
                    templateImageSvgPath,
                };

                const response = await this.api.post("/api/printtemplate", requestBody);

                const createPrintTemplateResponse = response.data as CreatePrintTemplateResponse;
                resolve(createPrintTemplateResponse.id);
            } catch (err) {
                reject(err);
            }
        });
    }

    update(id: number, height: number, width: number, description: string, templateImageSvgPath: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const requestBody: UpdatePrintTemplateRequest = {
                    id,
                    height,
                    width,
                    description,
                    templateImageSvgPath,
                };

                const response = await this.api.patch("/api/printtemplate", requestBody);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    delete(id: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.delete(`/api/printtemplate?id=${id}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }
}