import { AuditDetailsBasicInfo } from "types/dsp/audits";

type AuditDetailsStateType = {
    auditName?: string
    auditStatus?: number
}

type AuditDetailsReducerAction = 
    | { type: "SET_AUDIT_INFO", payload: AuditDetailsBasicInfo }

const auditDetailsInitialState: AuditDetailsStateType = {
    auditName: undefined,
    auditStatus: undefined,
};

function AuditDetailsReducer(state: AuditDetailsStateType, action: AuditDetailsReducerAction): AuditDetailsStateType {
    switch (action.type) {
        case "SET_AUDIT_INFO":
            return {
                ...state,
                auditName: action.payload.auditName,
                auditStatus: action.payload.auditStatus,
            };
    }
}

export {
    auditDetailsInitialState,
    AuditDetailsReducer
}