import React from "react";
import { NavButtonBase } from "../../base/button";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

export function ReportingButton(): JSX.Element {
    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (process.env.REACT_APP_API_URL) {
                case "https://cloudapi.dealerbuilt.com/scan/v2/staging":
                case "https://localhost:56388":
                    return (
                        <NavButtonBase
                            icon={<AssessmentOutlinedIcon />}
                            navigateDestination="/reporting"
                            text="Business Intelligence"
                            />
                    );
                default:
                    return (<></>);
            }
        })
    }, []);

    return (component);
}