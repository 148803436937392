import React from "react";
import { useDspAuditVarianceContext } from "../../context";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { DspAuditVariance } from "types/dsp/audits";
import { pendingColumns, rescanColumns } from "./columns";
import { GenericDataGrid } from "../../../../../base/dataGrid";

type MappedRow = DspAuditVariance & {
    id: number
    filter: "pending" | "rescans"
    toggleFilter: () => void
}

export function VarianceDataGrid(): JSX.Element {
    const { auditVariances, rescanIds, selectedTab, toggleFilter } = useDspAuditVarianceContext();
    const gridApiRef = useGridApiRef();

    const columnsMemo = React.useMemo((): GridColDef[] => {
        switch (selectedTab) {
            case "pending":
                return pendingColumns;
            case  "rescans":
                return rescanColumns;
            default:
                return [];
        }
    }, [selectedTab]);

    const mappedRows = React.useMemo((): MappedRow[] => {
        if (!auditVariances) {
            return [];
        }

        return auditVariances.map((value: DspAuditVariance): MappedRow => {
            return {
                ...value,
                id: value.lightyearPartId,
                filter: rescanIds.has(value.lightyearPartId) ? "rescans" : "pending",
                toggleFilter: () => toggleFilter(value.lightyearPartId),
            };
        });
    }, [auditVariances, rescanIds, toggleFilter]);

    React.useEffect(() => {
        switch (selectedTab) {
            case "pending":
                gridApiRef.current.setFilterModel({ items: [{ id: 0, field: "filter", operator: "equals", value: "pending" }]});
                break;
            case "rescans":
                gridApiRef.current.setFilterModel({ items: [{ id: 1, field: "filter", operator: "equals", value: "rescans" }]});
                break;
            default:
                gridApiRef.current.setFilterModel({ items: []});
                break;
        }
    }, [gridApiRef, selectedTab]);

    return (
        <GenericDataGrid
            apiRef={gridApiRef}
            columns={columnsMemo}
            columnVisibilityModel={{
                filter: false,
            }}
            getRowHeight={() => "auto"}
            rows={mappedRows}
            sx={{
                "& .MuiDataGrid-cell": {
                    alignItems: "center",
                    display: "flex",
                },
                "& .cellWordWrap": {
                    overflow: "visible",
                    textOverflow: "clip",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
            }}
            />
    )
}